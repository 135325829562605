import Card from "react-bootstrap/Card";
import ListGroup from "react-bootstrap/ListGroup";
import cambridgeLogo from "./cambridge_logo.png";
import markovChainLogo from "./markov_chain_logo.png";

import CategoryDisplay from "../CategoryDisplay";

function MathsCategoryDisplay() {
	return (
		<CategoryDisplay
			categoryName="Mathematics"
			categoryLeadParagraph="During my time at Cambridge, I studied a variety of areas within mathematics. I was particularly interested in statistics, probability, and machine learning. Below are some projects for things that I've looked into:"
		>
			<Card>
				<Card.Img variant="top" src={markovChainLogo} />
				<Card.Body>
					<Card.Title>Markov Chains</Card.Title>
					<Card.Text>While I was at university, I took several courses about Markov chains.
						Every now and then, I decide to revisit this topic and make some notes about
						something that has caught my interest; below is a link to those notes:</Card.Text>
				</Card.Body>
				<ListGroup className="list-group-flush"></ListGroup>
				<Card.Body>
					<Card.Link href="https://github.com/Unoblueboy/Markov-Chains">Git Repository</Card.Link>
				</Card.Body>
			</Card>
			<Card>
				<Card.Img variant="top" src={cambridgeLogo} style={{backgroundColor: "#FFFFFF"}} />
				<Card.Body>
					<Card.Title>Uni Projects</Card.Title>
					<Card.Text>I completed several CATAM (Computer-Aided Teaching of All Mathematics) 
						projects as a part of my university degree. Below are links to two specific 
						projects as well as a link to the git repository containing all of the projects:</Card.Text>
				</Card.Body>
				<ListGroup className="list-group-flush">
					<ListGroup.Item>
						<Card.Link href="https://github.com/Unoblueboy/CATAM/tree/master/II/07.03%20-%20Minimisation%20Methods">
							Minimisation Methods
						</Card.Link>
					</ListGroup.Item>
					<ListGroup.Item>
						<Card.Link href="https://github.com/Unoblueboy/CATAM/tree/master/II/10.09%20-%20Markov%20Chain%20Monte%20Carlo">
							Markov Chain Monte Carlo
						</Card.Link>
					</ListGroup.Item>
				</ListGroup>
				<Card.Body>
					<Card.Link href="https://github.com/Unoblueboy/CATAM">Git Repository</Card.Link>
				</Card.Body>
			</Card>
		</CategoryDisplay>
	);
}

export default MathsCategoryDisplay;
