import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

import "./Footer.scss"

function Footer(){
    return (<footer>
				<Container>
					<Col md={4} className="footer-left">
						All content © Nathan Douglas
					</Col>
					<Col md={4} className="footer-right list-unstyled">
						<a href="https://github.com/Unoblueboy" aria-label="My GitHub profile">
							<FontAwesomeIcon icon={faGithub} size="lg" />
						</a>
						<a href="https://www.linkedin.com/in/n-douglas/" aria-label="My Linkedin profile">
							<FontAwesomeIcon icon={faLinkedin} size="lg" />
						</a>
					</Col>
				</Container>
			</footer>)
}

export default Footer;