import { useState } from 'react';

import Card from "react-bootstrap/Card";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ListGroup from "react-bootstrap/ListGroup";
import discordLogo from "./discord_logo.png";
import telloLogo from "./tello_logo.png";
import websiteLogo from "./website_logo.png";

import CategoryDisplay from "../CategoryDisplay";


function ProgrammingCategoryDisplay() {
	const [modalShow, setModalShow] = useState(false)

	return (
		<>
			<CategoryDisplay
				categoryName="Programming"
				categoryLeadParagraph="I got into programming at some point during high school, and since then I have worked on a few projects here and there. Below is a selection of them:"
			>
				<Card>
					<Card.Img variant="top" src={discordLogo} />
					<Card.Body>
						<Card.Title>Discord Bots</Card.Title>
						<Card.Text>Listed below are 2 Discord bots I wrote in Python. The dice bot allows users to roll dice in a
							Discord server using syntax similar to what is used 
							by <a href="https://roll20.net/">Roll20</a> and <a href="https://foundryvtt.com/">Foundry VTT</a>. 
							The Secret Hitler bot allows users to play <a href="https://www.secrethitler.com/">Secret Hitler</a> in 
							a Discord server.
						</Card.Text>
					</Card.Body>
					<ListGroup className="list-group-flush">
						<ListGroup.Item>
							<Card.Link href="https://github.com/Unoblueboy/Dice-Bot">
								Dice Bot
							</Card.Link>
						</ListGroup.Item>
						<ListGroup.Item>
							<Card.Link href="https://github.com/Unoblueboy/Secret-Hitler-Discord-Bot">
								Secret Hitler Bot
							</Card.Link>
						</ListGroup.Item>
					</ListGroup>
					<Card.Body>
					</Card.Body>
				</Card>
				<Card>
					<Card.Img variant="top" src={telloLogo} />
					<Card.Body>
						<Card.Title>Tello Drone Project</Card.Title>
						<Card.Text>I wanted to learn to fly a drone and also how to interface with tech using sockets, 
							so I got a DJI Tello drone. Below is the link to a Python library I created to control a 
							Tello drone and to retrieve data from it (including its state and video stream).</Card.Text>
					</Card.Body>
					<ListGroup className="list-group-flush">
					</ListGroup>
					<Card.Body>
						<Card.Link href="https://github.com/Unoblueboy/Tello">Git Repository</Card.Link>
					</Card.Body>
				</Card>
				<Card>
					<Card.Img variant="top" src={websiteLogo} />
					<Card.Body>
						<Card.Title>This Website</Card.Title>
						<Card.Text>This website is written in react, with Sass for styling. I might add more in the future 
							depending on what projects I think up.</Card.Text>
					</Card.Body>
					<ListGroup className="list-group-flush">
					</ListGroup>
					<Card.Body>
						<Card.Link style={{"cursor": "pointer"}} onClick={() => setModalShow(true)}>Link to Site</Card.Link>
					</Card.Body>
				</Card>
			</CategoryDisplay>
			<Modal show={modalShow} onHide={() => setModalShow(false)}>
				<Modal.Header closeButton>
					<Modal.Title>This is the website</Modal.Title>
				</Modal.Header>
				<Modal.Body>You're already here; you don't need to go anywhere. I might as well include a few more details 
					about how this website works.
					<br/>
					<br/>
					This website is a React web app using TypeScript with Sass for styling the components. The website is 
					pushed to a private repository on GitHub, and then a GitHub action is run on push to main to build the 
					web app and deploy it to the website using FTP.
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={() => setModalShow(false)}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

export default ProgrammingCategoryDisplay;
